import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useUser } from '@auth0/nextjs-auth0'

const ICON_SIZE = 60

const useStyles = makeStyles((theme) => ({
  button: {},
  iconStyle: {
    fontSize: ICON_SIZE,
    color: theme.palette.primary.main,
  },
  avatar: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    borderRadius: ICON_SIZE,
  },
}))

export function Avatar() {
  const { user } = useUser()
  const classes = useStyles()

  if (!user) {
    return null
    // return (
    //   <AccountCircleOutlinedIcon className={classes.iconStyle} />
    // )
  }

  return <img src={user.picture} className={classes.avatar} />
}
