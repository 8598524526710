import { links, getAppLinkHref, getCustomerLinkHref } from '@slc/links'
import { useGetApi as $useGetApi } from './useApi'
import { appUrl, customerUrl, apiUrl } from '../url'

const appOptions = {
  baseApiUrl: appUrl,
  signinUrl: getAppLinkHref(links.app.signin)
}

export const useAppApi = path => $useGetApi(path, appOptions)

const customerOptions = {
  baseApiUrl: customerUrl,
  signinUrl: getCustomerLinkHref(links.customer.signin)
}

export const useCustomerApi = path => $useGetApi(path, customerOptions)

const apiOptions = {
  baseApiUrl: apiUrl,
  signinUrl: getCustomerLinkHref(links.customer.signin)
}

export const useApi = path => $useGetApi(path, apiOptions)
