import React from 'react'
import { App } from '@slc/appshell'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '@slc/ui'
import { SWRConfig } from 'swr'
import { useStore } from '@/utils/store'
import { Layout } from '@/components/Layout'
import { theme } from '@/components/theme'

export default function MyApp(props) {
  const { Component, pageProps } = props

  const store = useStore(pageProps.initialReduxState)

  Component.isAuth = true

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SWRConfig>
        <App
          store={store}
          Component={Component}
          pageProps={pageProps}
          Layout={Layout}
          theme={theme}
        />
      </SWRConfig>
    </ErrorBoundary>
  )
}
