import get from 'lodash/get'
import has from 'lodash/has'
import flatten from 'lodash/flatten'
import { logger } from '@slc/logger'

export const getCustomerName = customer => {
  if (!customer) {
    throw new Error('Missing customer')
  }

  logger.debug('customer name ?', customer)

  const { firm, firstname, lastname } = customer.infos

  if (firm) {
    logger.debug(' ~> use firm name', firm)
    return firm
  }

  const fullName = flatten([firstname, lastname]).join(' ')
  logger.debug(' ~> use full name', fullName)

  return fullName
}

export const hasStripeAccount = customer => {
  if (!customer) {
    throw new Error('Missing customer')
  }

  return has(customer, 'account.stripeId')
}

export const getStripeAccountId = customer => {
  if (!customer) {
    throw new Error('Missing customer')
  }

  return get(customer, 'account.stripeId')
}

export const getCustomerLang = customer => {
  if (!customer) {
    throw new Error('Missing customer')
  }

  return get(customer, 'defaults.language', process.env.SITE)
}

export const getCustomerCurrency = customer => {
  if (!customer) {
    throw new Error('Missing customer')
  }

  return get(customer, 'defaults.currency', process.env.SITE_CURRENCY)
}
