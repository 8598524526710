import { React, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

import { PageLoader } from '@slc/ui'
import { insertQueryIntoUrl } from '@slc/utils'
import { useAppApi } from '@slc/fetch'

import { Trans } from '@lingui/macro'
import { FetchError } from './FetchError'

const LIMIT = 10

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  pagination: {
    textAlign: 'center',
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
}))

export function EmptyPlaceholder({ className }) {
  return (
    <div className={className}>
      <Trans id="customer.list.empty.placeholder" />
    </div>
  )
}

export function ListContent({
  results = [],
  render,
  total,
  skip,
  limit,
  page,
  setPage,
}) {
  const classes = useStyles()
  const pageCount = limit > 0 ? Math.round(total / limit) : 1
  const currentPage = limit > 0 ? Math.round(skip / limit) : 0
  const hasPages = total > limit

  const handleChange = (event, value) => {
    setPage(value)
  }

  if (!results.length) {
    return <EmptyPlaceholder className={classes.empty} />
  }

  return (
    <div className={classes.root}>
      {render({ results })}

      {hasPages && (
        <div className={classes.pagination}>
          <Pagination
            color="primary"
            shape="rounded"
            count={pageCount}
            defaultPage={currentPage || page}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

export function PaginatedContent({
  fetchInfos,
  toolbar = null,
  filters,
  render,
}) {
  const classes = useStyles()

  const [page, setPage] = useState(1)

  const { data, error } = useAppApi(
    insertQueryIntoUrl(
      `${fetchInfos}?limit=${LIMIT}&skip=${(page - 1) * LIMIT}`,
      filters,
    ),
  )

  const hasFetched = data !== undefined || error !== undefined

  let content = null

  if (hasFetched) {
    content = error ? (
      <FetchError error={error} />
    ) : (
      <ListContent {...data} page={page} setPage={setPage} render={render} />
    )
  }

  return (
    <div className={classes.root}>
      {toolbar}
      {!hasFetched ? <PageLoader /> : content}
    </div>
  )
}
