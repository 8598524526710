import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'

import Header from '../Header'
import Footer from '../Footer'

import { ScrollToTop, scrollToTop } from '@slc/ui'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  anchor: {
    height: 205
  },
  main: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  container: {
    minHeight: '50vh',
    maxWidth: 960
  }
}))

export const Layout = ({ primaryLinks, secondaryLinks, children }) => {
  const classes = useStyles()

  useEffect(() => {
    scrollToTop()
  })

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Header
        primaryLinks={primaryLinks}
        secondaryLinks={secondaryLinks}
      />

      <div id='back-to-top-anchor' className={classes.anchor} />
      <main className={classes.main}>
        <Container maxWidth='lg' className={classes.container}>
          {children}
        </Container>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  )
}

export default Layout
