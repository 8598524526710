import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { cartPricing } from '@slc/pricing'
import {
  CUSTOM_MESSAGE,
  MP3_FORMAT,
  DOWNLOAD_SUPPORT,
  AMOUNT_DISCOUNT,
  PERCENT_DISCOUNT,
  PERMANENT_PROMOCODE,
} from '@slc/constants'

import { useSelector } from 'react-redux'
import property from 'lodash/property'

import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  messages: [],
  pricing: {},
  cart: {
    messages: [],
    options: {
      total: 0,
      options: [],
    },
    promoCode: {
      pcid: '',
      percent: 0,
      value: 0,
    },
    taxes: {
      name: '',
      percent: 0,
    },
    total: {
      subtotal: 0,
      options: 0,
      taxes: 0,
      total: 0,
    },
  },
  options: {
    total: 0,
    options: [],
  },
  total: {
    subtotal: 0,
    options: 0,
    taxes: 0,
    total: 0,
  },
  promoCode: {
    pcid: '',
    name: '',
    type: null,
  },
  discount: {
    value: 0,
  },
  taxes: {
    name: '',
    percent: 0,
  },
  format: {
    name: MP3_FORMAT,
    value: 0,
  },
  support: {
    name: DOWNLOAD_SUPPORT,
    value: 0,
  },
  acceptation: false,
  paymentType: '',
  order: null,
  name: '',
}

const resetCartStatus = (state) => {
  state.cart = initialState.cart
  state.total = initialState.total
  state.format = initialState.format
  state.acceptation = initialState.acceptation
  state.paymentType = initialState.paymentType
  state.name = initialState.name
}

const updateOptionTotal = (state) => {
  const options = get(state, 'options.options', [])

  state.options.total = options.reduce((acc, { value }) => acc + value, 0)

  updateTotal(state)
}

const updateCart = (state) => {
  if (isEmpty(state.messages)) {
    resetCartStatus(state)
    return
  }

  if (!isEmpty(state.pricing)) {
    state.cart = cartPricing(state.messages, state.pricing, true)

    const { promoCode } = state

    if (
      promoCode.pcid &&
      promoCode.type === PERMANENT_PROMOCODE &&
      promoCode.availability &&
      state.messages.length < promoCode.availability
    ) {
      state.promoCode = initialState.promoCode
      state.discount = initialState.discount
    }
    updateTotal(state)
  }
}

const computeDiscount = (subtotal, promoCode = {}) => {
  if (!has(promoCode, 'bonus.unit')) {
    return 0
  }

  const { value, unit } = promoCode.bonus

  switch (unit) {
    case AMOUNT_DISCOUNT: {
      return value
    }

    case PERCENT_DISCOUNT: {
      return Math.round((subtotal * Math.abs(value)) / 100)
    }

    default: {
      return 0
    }
  }
}

const updateTotal = (state) => {
  if (state.cart.total > 0) {
    const support = get(state.support, 'value', 0)
    const taxPercent = get(state.taxes, 'percent', 0)
    const totalOptions = get(state.options, 'total', 0)

    const totalLines = state.cart.total + support + totalOptions

    const discount = computeDiscount(totalLines, state.promoCode)

    const subtotal = discount > 0 ? totalLines - discount : totalLines

    const taxes = taxPercent > 0 ? Math.floor(subtotal * taxPercent) / 100 : 0

    state.discount = {
      value: discount,
      bonus: state.promoCode.bonus,
    }

    state.total = {
      subtotal,
      taxes,
      total: subtotal + taxes,
    }
  }
}

const extractMessage = ({ category, selection }) => {
  if (category !== CUSTOM_MESSAGE) {
    return selection[category]
  }

  const { blocks, translation, origin } = selection[CUSTOM_MESSAGE]

  return !isEmpty(origin.lang) &&
    !isEmpty(origin.from) &&
    !isEmpty(origin.text) &&
    translation.includes(origin.lang)
    ? selection[CUSTOM_MESSAGE]
    : { blocks, translation }
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addMessage(state, action) {
      const { music, langs, voices, message } = action.payload
      const { type, category, instructions, options } = message

      state.messages.push({
        mid: uuid(),
        music,
        voices,
        langs,
        message: {
          type,
          category,
          instructions,
          options,
          selection: extractMessage(message),
        },
      })

      updateCart(state)
    },

    removeMessage(state, action) {
      const { mid } = action.payload
      const index = state.messages.findIndex((message) => message.mid === mid)

      if (index === -1) {
        return
      }

      state.messages.splice(index, 1)

      updateCart(state)
    },

    removePart(state, action) {
      const { mid, pid } = action.payload

      const message = find(state.cart.messages, { mid })
      const { lid } = find(message.parts, { pid })

      const index = state.messages.findIndex((message) => message.mid === mid)
      const messageInfos = state.messages[index]

      messageInfos.voices = messageInfos.voices.filter(
        (voice) => voice.lid !== lid,
      )
      messageInfos.langs = messageInfos.langs.filter((lang) => lang.lid !== lid)

      if (messageInfos.message.category === CUSTOM_MESSAGE) {
        const { selection } = messageInfos.message

        messageInfos.message.selection.blocks = selection.blocks.filter(
          ({ lang }) => lang.lid !== lid,
        )

        messageInfos.message.selection.translation =
          selection.translation.filter(($lid) => $lid !== lid)
      }

      state.messages[index] = messageInfos

      updateCart(state)
    },

    setSupport(state, action) {
      const { name, value } = action.payload

      state.support = { name, value }

      updateTotal(state)
    },

    setFormat(state, action) {
      const { name, value } = action.payload

      state.format = { name, value }
    },

    toggleOption(state, action) {
      const { name, value } = action.payload

      const options = get(state, 'options.options', [])

      const index = options.findIndex((option) => option.name === name)

      index > -1 ? options.splice(index, 1) : options.push({ name, value })

      updateOptionTotal(state)
    },

    setTaxes(state, action) {
      const { name, percent } = action.payload

      state.taxes = { name, percent }

      updateTotal(state)
    },

    setPromoCode(state, action) {
      const { promoCode } = action.payload

      state.promoCode = promoCode

      updateTotal(state)
    },

    setAcceptation(state, action) {
      const { acceptation } = action.payload

      state.acceptation = acceptation
    },

    setPaymentType(state, action) {
      const { paymentType } = action.payload

      state.paymentType = paymentType
    },

    setOrder(state, action) {
      const { order } = action.payload

      state.order = order
    },

    setName(state, action) {
      const { name } = action.payload

      state.name = name
    },

    refreshCart(state, action) {
      updateCart(state)
    },

    resetCart(state, action) {
      state.messages = initialState.messages

      updateCart(state)
    },

    setPaymentComplete(state) {
      state.payment = initialState.payment
      state.order = initialState.order
    },
  },
  extraReducers: {
    'pricing/setPricing': (state, action) => {
      state.pricing = action.payload

      updateCart(state)
    },
  },
})

const { actions, reducer } = cartSlice

export const {
  addMessage,
  removeMessage,
  removePart,
  setSupport,
  setFormat,
  toggleOption,
  setTaxes,
  setPromoCode,
  setAcceptation,
  setPaymentType,
  setOrder,
  setName,
  refreshCart,
  resetCart,
  setPaymentComplete,
} = actions

export const useMusic = () => useSelector(property('wizard.music'))
export const useLangs = () => useSelector(property('wizard.langs'))
export const useVoices = () => useSelector(property('wizard.voices'))
export const useMessage = () => useSelector(property('wizard.message'))

export default reducer
