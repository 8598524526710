import React from 'react'
import Container from '@material-ui/core/Container'

import Menu from './Menu'
import Newsletter from './Newsletter'
import BottomBar from './BottomBar'

import { bottomMenu, footerMenus } from '../../config/links'

export const Footer = () => {
  return (
    <footer>
      <Menu links={footerMenus} />
      <Container maxWidth='lg'>
        <Newsletter />
        <BottomBar links={bottomMenu} />
      </Container>
    </footer>
  )
}

export default Footer
