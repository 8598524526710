import zipObject from 'lodash/zipObject'

import { links as $app } from './app'
import { links as $auth } from './auth'
import { links as $customer } from './customer'
import { links as $dashboard } from './dashboard'

const exposeLinks = (name, $links) => {
  const $$links = Object.keys($links)

  return {
    $name: name,
    ...(
      zipObject(
        $$links,
        $$links.map(link => ({
          name,
          ...$links[link]
        }))
      )
    )
  }
}

export const app = exposeLinks('app', $app)
export const auth = exposeLinks('auth', $auth)
export const customer = exposeLinks('customer', $customer)
export const dashboard = exposeLinks('dashboard', $dashboard)

export const links = { app, auth, customer, dashboard }
