import { LayoutTree } from '@moxy/next-layout'
import { Layout as DefaultLayout } from '../Layout'
import { UserProvider as SessionProvider, withPageAuthRequired } from '@auth0/nextjs-auth0';
import { Persistence } from './persistence'

import { SnackbarProvider } from 'notistack'
import { CountryProvider } from '@slc/context'

export const AppLayout = ({ store, Layout = DefaultLayout, Component, pageProps, err }) => (
  <SessionProvider>
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <Persistence store={store}>
        {() => (
          <CountryProvider lang={process.env.NEXT_PUBLIC_SITE} countryInfos={pageProps.countryInfos}>
            <LayoutTree
              defaultLayout={<Layout />}
              Component={Component.isAuth ? withPageAuthRequired(Component) : Component}
              pageProps={pageProps}
              err={err}
            />
          </CountryProvider>
        )}
      </Persistence>
    </SnackbarProvider>
  </SessionProvider>
)
