import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Columns from './Columns'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing(8)}px 0`,
    borderTop: `thin solid ${theme.palette.input.main}`,
    borderBottom: `thin solid ${theme.palette.input.main}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: {
    marginTop: theme.spacing(3),
    textAlign: 'justify'
  }
}))

export const Menu = ({ links }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <div>
          <Typography component='h4' variant='h4'>
            <Trans id='footer.title' />
          </Typography>
        </div>
        <Columns links={links} />
        <Typography
          component='p'
          variant='body2'
          className={classes.description}
        >
          <Trans id='footer.description' />
        </Typography>
      </Container>
    </div>
  )
}

export default Menu
