import { createSlice } from '@reduxjs/toolkit'
import { buildTotalAndTaxes } from '@slc/utils'

const initialState = {
  selection: {
    amount: 0,
    bonus: 0,
    creditsEarn: 0
  },
  total: {
    subtotal: 0,
    taxes: 0,
    total: 0
  },
  taxInfos: {
    name: '',
    percent: 0
  },
  acceptation: false,
  paymentType: '',
  order: null,
  name: ''
}

const topupSlice = createSlice({
  name: 'topup',
  initialState,
  reducers: {
    addSelection(state, action) {
      const {
        amount,
        bonus,
        creditsEarn,
      } = action.payload

      state.selection = {
        amount,
        bonus,
        creditsEarn
      }
    },

    setOrder(state, action) {
      const { order } = action.payload

      state.order = order
    },

    setOrderInfos(state, action) {
      const { name, acceptation, paymentType } = action.payload

      state.name = name
      state.acceptation = acceptation
      state.paymentType = paymentType
    },
    setTotalInfos(state, action) {
      const { subtotal, taxInfos } = action.payload

      state.total = buildTotalAndTaxes({ subtotal, taxInfos })
      state.taxInfos = taxInfos
    },

    setOrderComplete(state) {
      state.selection = initialState.selection
      state.total = initialState.total
      state.taxInfos = initialState.taxInfos
      state.acceptation = initialState.acceptation
      state.paymentType = initialState.paymentType
      state.order = initialState.order
      state.name = initialState.name
    }
  }
})

const { actions, reducer } = topupSlice

export const {
  addSelection,
  setOrderInfos,
  setTotalInfos,
  setOrder,
  setOrderComplete
} = actions

export default reducer
