import { combineReducers } from 'redux'

import { country, pricing } from '@slc/store'

import topupReducer, * as topup from './topup'

const rootReducer = combineReducers({
  topup: topupReducer,
  country: country.default,
  pricing: pricing.default
})

export {
  rootReducer as default,
  topup,
  country,
  pricing
}
