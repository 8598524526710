import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'

import { OrderAcceptation } from '../OrderAcceptation'
import { OrderName } from '../OrderName'
import { PaymentForm } from '../PaymentForm'

const useStyles = makeStyles((theme) => ({
  formContent: {
    display: 'grid',
    gridRowGap: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  divider: {
    marginInline: theme.spacing(10),
    marginBlock: theme.spacing(2),
  },
  name: {
    maxWidth: '320px',
  },
}))

export function OrderCompletion({
  onCompletion,
  useOrder,
  deniedPaymentTypes = [],
}) {
  const classes = useStyles()
  const {
    amount,
    name,
    acceptation,
    paymentType,
    isValid,
    setName,
    addAcceptation,
    addPaymentType,
  } = useOrder()

  useEffect(() => {
    if (isValid) {
      onCompletion({
        name,
        acceptation,
        paymentType,
      })
    }
  }, [onCompletion, isValid])

  return (
    <Paper variant="outlined" className={classes.formContent}>
      <div className={classes.name}>
        <OrderName
          value={name}
          onChange={(value) => {
            setName(value)
          }}
        />
      </div>

      <OrderAcceptation
        value={acceptation}
        onChange={(value) => {
          addAcceptation(value)
        }}
      />

      <Divider className={classes.divider} />

      <PaymentForm
        deniedPaymentTypes={deniedPaymentTypes}
        amount={amount}
        selection={paymentType}
        onChange={(e) => {
          addPaymentType(e)
        }}
      />
    </Paper>
  )
}
