import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Box from '@material-ui/core/Box'

import IconButton from '@material-ui/core/IconButton'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'

import { socials } from '@slc/app-links'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  button: {
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    transition: '0.3s cubic-bezier(.47,1,.41,.8)'
  },
  facebook: {
    '&:hover, &:focus': {
      color: '#3a579a'
    }
  },
  twitter: {
    '&:hover, &:focus': {
      color: '#29a8df'
    }
  },
  youtube: {
    '&:hover, &:focus': {
      color: '#e52d27'
    }
  }
}))

const socialButtons = ['facebook', 'twitter', 'youtube']
const socialsIcons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  youtube: YouTubeIcon
}

const SocialItem = ({ name, href, Icon, color }) => {
  const classes = useStyles()

  return (
    <a href={href} target='_blank' rel='noreferrer'>
      <IconButton
        aria-label={name}
        size='medium'
        className={clsx(classes.button, classes[name])}
        disableTouchRipple
        color={color}
      >
        <Icon />
      </IconButton>
    </a>
  )
}

const Socials = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {socialButtons.map(social => (
        <SocialItem
          key={social}
          name={social}
          href={socials[social]}
          Icon={socialsIcons[social]}
        />
      ))}
    </Box>
  )
}

export default Socials
