import { createTheme } from '@material-ui/core/styles'
import { theme as genericTheme } from '@slc/ui'
import defaultsDeep from 'lodash/defaultsDeep'

export const theme = createTheme(
  defaultsDeep(
    {
      overrides: {
        MuiPaper: {
          root: {
            borderWidth: 0,
          },
        },
        MuiCard: {
          root: {
            borderWidth: 0,
          },
        },
        MuiPagination: {
          ul: {
            justifyContent: 'center',
          },
        },
      },
      props: {
        MuiPaper: {
          elevation: 0,
        },
        MuiCard: {
          elevation: 0,
        },
      },
    },
    genericTheme,
  ),
)
