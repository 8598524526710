import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import NextLink from 'next/link'
import { Tooltip } from '../tooltip/Tooltip'

function Button({ tooltip, children, ...others }) {
  return (
    <IconButton variant="outlined" {...others}>
      {children}
    </IconButton>
  )
}

function TooltipButton({ tooltip, children, ...others }) {
  return !tooltip ? (
    <Button {...others}>{children}</Button>
  ) : (
    <Tooltip title={tooltip}>
      <Button {...others}>{children}</Button>
    </Tooltip>
  )
}

export function ActionButton({ tooltip, href, onClick, children, ...others }) {
  if (href) {
    return (
      <NextLink href={href} passHref>
        <a>
          <TooltipButton tooltip={tooltip} {...others}>
            {children}
          </TooltipButton>
        </a>
      </NextLink>
    )
  }

  if (onClick) {
    others.onClick = onClick
  }

  return (
    <TooltipButton tooltip={tooltip} {...others}>
      {children}
    </TooltipButton>
  )
}
