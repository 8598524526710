import { links, getAppLinkHref } from '@slc/app-links'

const { app } = links

const l = link => ({
  ...link,
  href: getAppLinkHref(link)
})

export const footerMenus = [
  [
    l(app.home),
    l(app.contact),
    l(app.auth)
  ],
  [
    l(app.about),
    l(app.pricing)
    // app.terms
  ]
]

export const topMenu = [
  l(app.support),
  l(app.affiliate)
]

export const mainMenu = [
  l(app.home),
  l(app.order),
  l(app.pricing),
  l(app.about),
  l(app.contact)
]

export const secondaryMenu = [
  l(app.customer),
  l(app.cart)
]

export const bottomMenu = [
  l(app.terms),
  l(app.privacy),
  l(app.cookies),
  l(app.notice)
]
