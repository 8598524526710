export const CREATED_QUOTE = 'created'
export const ACCEPTED_QUOTE = 'accepted'
export const CANCELLED_QUOTE = 'cancelled'

export const QUOTE_STATUSES = [
  CREATED_QUOTE,
  ACCEPTED_QUOTE,
  CANCELLED_QUOTE
]

export const CREATED_ORDER = 'created'
export const ACCEPTED_ORDER = 'accepted'
export const CANCELLED_ORDER = 'cancelled'
export const WAITING_PAYMENT_ORDER = 'waiting_payment'
export const PAYMENT_CONFIRMED_ORDER = 'payment_confirmed'
export const PAID_ORDER = 'paid'
export const PAYMENT_TYPE_CHANGE_ORDER = 'payment_type_change'
export const PAYMENT_ISSUE_ORDER = 'payment_issue'
export const OVERDUE_ORDER = 'overdue'
export const PRODUCTION_ORDER = 'production'
export const COMPLETE_ORDER = 'complete'
export const REFUND_ORDER = 'refund'

export const GENERIC_ORDER_STATUSES = [
  CREATED_ORDER,
  ACCEPTED_ORDER,
  CANCELLED_ORDER,
  PAID_ORDER,
  OVERDUE_ORDER,
  COMPLETE_ORDER,
  REFUND_ORDER
]

export const NORMAL_ORDER_STATUSES = [
  CREATED_ORDER,
  ACCEPTED_ORDER,
  WAITING_PAYMENT_ORDER,
  PAYMENT_CONFIRMED_ORDER,
  PAID_ORDER,
  PRODUCTION_ORDER,
  COMPLETE_ORDER
]

// export const REDUCED_ORDER_STATUSES = [
//   CREATED_ORDER,
//   PAID_ORDER,
//   PRODUCTION_ORDER,
//   COMPLETE_ORDER
// ]

export const ORDER_STATUSES = [
  CREATED_ORDER,
  ACCEPTED_ORDER,
  CANCELLED_ORDER,
  WAITING_PAYMENT_ORDER,
  PAYMENT_CONFIRMED_ORDER,
  PAID_ORDER,
  PAYMENT_TYPE_CHANGE_ORDER,
  PAYMENT_ISSUE_ORDER,
  PRODUCTION_ORDER,
  COMPLETE_ORDER,
  REFUND_ORDER
]

export const ORDER = 'order'
export const QUOTE = 'quote'

export const FUNNEL_ORDER_TYPE = 'funnel'
export const CREDIT_ORDER_TYPE = 'credit'
export const GSM_ORDER_TYPE = 'gsm'
export const DNS_ORDER_TYPE = 'dns'
export const MIXING_ORDER_TYPE = 'mixing'
export const OTHER_ORDER_TYPE = 'other'

export const ORDER_TYPES = [
  FUNNEL_ORDER_TYPE,
  CREDIT_ORDER_TYPE,
  GSM_ORDER_TYPE,
  DNS_ORDER_TYPE,
  MIXING_ORDER_TYPE,
  OTHER_ORDER_TYPE
]

export const WAITING_PAYMENT_STATUS = 'requires_payment_method'
export const SUCCEED_PAYMENT_STATUS = 'succeeded'
export const FAILED_PAYMENT_STATUS = 'payment_failed'
export const DISPUTED_PAYMENT_STATUS = 'payment_dispute'
export const FRAUD_PAYMENT_STATUS = 'payment_fraud'
export const CANCELED_PAYMENT_STATUS = 'canceled'
// specific status to indicate Stripe client SDK received validation
// but waiting stripe webhook to change to succeeded status
export const CONFIRMATION_PAYMENT_STATUS = 'payment_confirmation'

// TODO add specific message
export const AUTHORIZATION_NEEDED_PAYMENT_STATUS = 'AUTHORIZATION_NEEDED_PAYMENT_STATUS'
export const ERROR_PAYMENT_STATUS = 'ERROR_PAYMENT_STATUS'

export const ORDER_PAYMENT_STATUSES = [
  WAITING_PAYMENT_STATUS,
  CONFIRMATION_PAYMENT_STATUS,
  SUCCEED_PAYMENT_STATUS,
  FAILED_PAYMENT_STATUS,
  DISPUTED_PAYMENT_STATUS,
  FRAUD_PAYMENT_STATUS,
  AUTHORIZATION_NEEDED_PAYMENT_STATUS,
  ERROR_PAYMENT_STATUS
]

export const CREDIT_ORDER_ITEM_TYPE = 'credit'

// GENERIC REF TYPE
export const GENERIC_ORDER_REF_TYPE = 'other'
export const DISCOUNT_ORDER_REF_TYPE = 'discount'

// FUNNEL REF TYPE
export const MESSAGE_ORDER_REF_TYPE = 'message'
export const MESSAGE_OPTION_ORDER_REF_TYPE = 'messageOption'
export const CART_OPTION_ORDER_REF_TYPE = 'cartOptions'
export const FORMAT_ORDER_REF_TYPE = 'format'
export const SUPPORT_ORDER_REF_TYPE = 'support'

// FUNNEL MESSAGE SUB TYPE
export const BASE_ORDER_MESSAGE_PART = 'base'
export const EXTRA_ORDER_MESSAGE_PART = 'extra'
export const TRANSLATION_ORDER_MESSAGE_PART = 'translation'
