import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import NextLink from 'next/link'
import Link from '@material-ui/core/Link'

import Copyright from './Copyright'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),

    borderTopColor: '#cdcdcd',
    borderTopStyle: 'solid',
    borderTopWidth: 1,

    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    margin: `0 ${theme.spacing(1)}px`,
    color: '#777',
    cursor: 'pointer'
  }
}))

export const BottomBar = ({ links }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        {links.map((item, index) => (
          <NextLink key={index} href={item.href}>
            <Link className={classes.link}>
              <Trans id={item.title.id} />
            </Link>
          </NextLink>
        ))}
      </div>
      <Copyright />
    </div>
  )
}

export default BottomBar
