import { defineMessage } from '@lingui/macro'

export const links = {
  dashboard: {
    href: '/',
    title: defineMessage({
      id: 'links.customer.dashboard'
    })
  },
  orders: {
    href: '/order',
    title: defineMessage({
      id: 'links.customer.orders'
    })
  },
  order: {
    href: '/order/:oid',
    title: defineMessage({
      id: 'links.customer.order'
    })
  },
  orderPayment: {
    href: '/order/:oid/payment',
    title: defineMessage({
      id: 'links.customer.payment'
    })
  },
  orderSelectPayment: {
    href: '/order/:oid/payment/select',
    title: defineMessage({
      id: 'links.customer.selectPayment'
    })
  },
  waitingPayment: {
    href: '/order/:oid/payment/waiting',
    title: defineMessage({
      id: 'links.customer.waitingPayment'
    })
  },
  creditPayment: {
    href: '/order/:oid/payment/credit',
    title: defineMessage({
      id: 'links.customer.creditPayment'
    })
  },
  creditPaymentComplete: {
    href: '/order/:oid/payment/credit/success',
    // title: defineMessage({
    //   id: 'links.customer.creditPaymentComplete'
    // })
  },
  creditPaymentFailure: {
    href: '/order/:oid/payment/credit/failure',
    // title: defineMessage({
    //   id: 'links.customer.creditPaymentFailure'
    // })
  },
  cartPayment: {
    href: '/order/:oid/payment/card',
    title: defineMessage({
      id: 'links.customer.cartPayment'
    })
  },
  cartPaymentComplete: {
    href: '/order/:oid/payment/card/success',
    // title: defineMessage({
    //   id: 'links.customer.cartPaymentComplete'
    // })
  },
  cartPaymentFailure: {
    href: '/order/:oid/payment/card/failure',
    // title: defineMessage({
    //   id: 'links.customer.cartPaymentFailure'
    // })
  },
  orderStudio: {
    href: '/order/:oid/studio',
    title: defineMessage({
      id: 'links.customer.orderStudio'
    })
  },
  orderCertificate: {
    href: '/order/:oid/certificate',
    title: defineMessage({
      id: 'links.customer.certificate'
    })
  },
  orderSupport: {
    href: '/order/:oid/support',
    title: defineMessage({
      id: 'links.customer.orderSupport'
    })
  },
  orderDetails: {
    href: '/order/:oid/details',
    title: defineMessage({
      id: 'links.customer.orderDetails'
    })
  },
  orderInvoice: {
    href: '/order/:oid/invoice',
    title: defineMessage({
      id: 'links.customer.invoice'
    })
  },
  downloadOrderInvoice: {
    href: '/order/:oid/invoice/download',
    title: defineMessage({
      id: 'links.customer.downloadInvoice'
    })
  },
  quotes: {
    href: '/quote',
    title: defineMessage({
      id: 'links.customer.quotes'
    })
  },
  quote: {
    href: '/quote/:oid',
    title: defineMessage({
      id: 'links.customer.quote'
    })
  },
  invoices: {
    href: '/invoices',
    title: defineMessage({
      id: 'links.customer.invoices'
    })
  },
  infos: {
    href: '/informations',
    title: defineMessage({
      id: 'links.customer.infos'
    })
  },
  editInfos: {
    href: '/informations/edit',
    title: defineMessage({
      id: 'links.customer.editInfos'
    })
  },
  topup: {
    href: '/credit/topup',
    title: defineMessage({
      id: 'links.customer.credit.topup'
    })
  },
  topupCheckout: {
    href: '/credit/topup/checkout',
    // title: defineMessage({
    //   id: 'links.customer.credit.topupCheckout'
    // })
  },
  statements: {
    href: '/credit/statement',
    title: defineMessage({
      id: 'links.customer.statements'
    })
  },
  support: {
    href: '/support',
    title: defineMessage({
      id: 'links.customer.support'
    })
  },
  signout: {
    href: '/api/auth/logout',
    title: defineMessage({
      id: 'links.customer.signout'
    })
  },
  signin: {
    href: '/auth/signin',
    title: defineMessage({
      id: 'links.customer.signin'
    })
  }
}
