import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone'

import Form from './Form'
import Socials from './Socials'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  },
  newsletterForm: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 2
  },
  notice: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  description: {
    marginLeft: theme.spacing(2)
  },
  title: {},
  text: {},
  form: {
    flexGrow: 2
  },
  socials: {
    flexGrow: 1
  }
}))

export const Newsletter = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.newsletterForm}>
        <div className={classes.notice}>
          <MailOutlineTwoToneIcon fontSize='large' />
          <div className={classes.description}>
            <Typography component='h4' variant='h6' className={classes.title}>
              <Trans id='newsletter.title' />
            </Typography>
            <Typography
              component='p'
              variant='caption'
              className={classes.text}
            >
              <Trans id='newsletter.description' />
            </Typography>
          </div>
        </div>
        <Form className={classes.form} />
      </div>
      <div className={classes.socials}>
        <Socials />
      </div>
    </div>
  )
}

export default Newsletter
