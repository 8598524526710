import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import NextLink from 'next/link'
import Link from '@material-ui/core/Link'

import { useRouter } from 'next/router'

import { locale as localeConfig } from '@slc/config'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginLeft: theme.spacing(1)
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {},
  button: {
    minWidth: 0,
    padding: 0,
    color: '#ababab',
    fontSize: '0.725rem',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  menu: {
    padding: 0
  },
  menuItem: {},
  menuItemLink: {
    color: '#ababab',
    fontSize: '0.725rem',
    textTransform: 'uppercase',
    pointerEvents: 'visible'
  }
}))

export const LangSelector = () => {
  const classes = useStyles()
  const { route, locale } = useRouter()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <div
          className={classes.button}
          aria-owns={open ? 'lang-selector' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          {locale}
        </div>
        <Popover
          id='lang-selector'
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          disableRestoreFocus
        >
          <List component='ul' className={classes.menu}>
            {Object.keys(localeConfig.langs).map(value => (
              <ListItem
                key={value}
                className={classes.menuItem}
                component='li'
              >
                <NextLink key={value} href={route} locale={value} passHref>
                  <Link className={classes.menuItemLink}>{localeConfig.langs[value]}</Link>
                </NextLink>
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    </ClickAwayListener>
  )
}

export default LangSelector
