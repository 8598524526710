import useSWR from 'swr'
import { $get } from '@slc/fetch'
import { useRouter } from 'next/router'
import get from 'lodash/get'

const fetcher = url => $get(url)

export const useGetApi = (path, options = {}) => {
  const { baseApiUrl, signinUrl } = options

  const router = useRouter()

  if (!path) {
    throw new Error('Path is required')
  }

  const { data, error } = useSWR(baseApiUrl(path), fetcher)

  const statusCode = data ? get(data, 'status') : null

  if (!isNaN(parseInt(data, 10))) {
    console.log('fetch error', data)
    return {
      statusCode: data,
      data: null,
      error,
      hasFetched: true,
      failed: Math.floor(data / 100) >= 4
    }
  }

  if ([401, 403].indexOf(statusCode) > -1) {
    router.push(signinUrl)
  }

  return {
    statusCode,
    data,
    error,
    hasFetched: statusCode !== null,
    failed: Math.floor(statusCode / 100) >= 4
  }
}
