export const CARD_PAYMENT = 'card'
export const TRANSFER_PAYMENT = 'transfer'
export const CHECK_PAYMENT = 'check'
export const CREDIT_PAYMENT = 'credit'

export const PAYMENT_TYPES = [
  CARD_PAYMENT,
  TRANSFER_PAYMENT,
  CHECK_PAYMENT,
  CREDIT_PAYMENT
]
