import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'

import orange from '@material-ui/core/colors/orange'
import get from 'lodash/get'

import { defineMessage, t } from '@lingui/macro'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 400,
    transition: 'border-color 0.3s cubic-bezier(.47,1,.41,.8)',
    borderColor: theme.palette.input.main,
    borderStyle: 'solid',
    borderWidth: 1,

    '&:hover, &:focus': {
      borderColor: orange[300]
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,

    color: theme.palette.input.main,
    transition: 'color 0.3s cubic-bezier(.47,1,.41,.8)',

    '&.hover': {
      color: orange[300]
    }
  },
  divider: {
    height: 28,
    margin: 4,

    transition: 'background-color 0.3s cubic-bezier(.47,1,.41,.8)',
    backgroundColor: theme.palette.input.main,

    '&.hover': {
      backgroundColor: orange[300]
    }
  }
}))

const labels = {
  placeholder: defineMessage({
    id: 'newsletter.placeholder'
  })
}

const Form = ({ className }) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)

  const onMouseEnter = () => setHover(true)
  const onMouseLeave = () => setHover(false)

  return (
    <Paper
      component='form'
      className={clsx(classes.root, className)}
      elevation={0}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <InputBase
        name='email'
        className={classes.input}
        placeholder={get(labels.placeholder, 'message', labels.placeholder.id)}
        inputProps={{
          'aria-label': defineMessage({
            id: 'newsletter.ariaLabel'
          }).message
        }}
      />
      <Divider
        orientation='vertical'
        className={clsx(classes.divider, { hover })}
      />

      <IconButton
        type='submit'
        color='primary'
        className={clsx(classes.iconButton, { hover })}
        aria-label={defineMessage({
          id: 'newsletter.aria_label'
        }).message}
      >
        <SendIcon />
      </IconButton>
    </Paper>
  )
}

export default Form
