import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { t } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

const Copyright = () => {
  const classes = useStyles()

  return (
    <Typography
      component='div'
      variant='caption'
      align='center'
      className={classes.root}
    >
      {`© ${new Date().getFullYear()} `}
      {t`copyright.name`}
    </Typography>
  )
}

export default Copyright
