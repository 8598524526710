import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import ScheduleIcon from '@material-ui/icons/Schedule'

import NextLink from 'next/link'
import Link from '@material-ui/core/Link'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  columns: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  infos: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoLine: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px 0`
  },
  infoIcon: {
    alignSelf: 'top'
  },
  infoText: {
    marginLeft: theme.spacing(1)
  },
  menu: {
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontSize: '0.725rem',
    lineHeight: '1.5rem',
    cursor: 'pointer'
  }
}))

export const Menu = ({ links }) => {
  const classes = useStyles()

  return (
    <div className={classes.columns}>
      <div className={classes.infos}>
        <div className={classes.infoLine}>
          <MailIcon className={classes.infoIcon} />
          <Typography
            variant='body1'
            component='div'
            className={classes.infoText}
          >
            <Trans id='footer.infos.email' />
          </Typography>
        </div>
        <div className={classes.infoLine}>
          <PhoneIcon className={classes.infoIcon} />
          <Typography
            variant='body1'
            component='div'
            className={classes.infoText}
          >
            <Trans id='footer.infos.phone' />
          </Typography>
        </div>
        <div className={classes.infoLine}>
          <ScheduleIcon className={classes.infoIcon} />
          <div className={classes.infoText}>
            <Typography variant='overline' component='div'>
              <Trans id='footer.infos.opening_hours.title' />{' '}
            </Typography>
            <Typography variant='body1' component='div'>
              <Trans id='footer.infos.hours.opening_hours.content' />
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.menu}>
        {links[0].map((item, index) => (
          <NextLink key={index} href={item.href}>
            <Link className={classes.link}>
              <Trans id={item.title.id} />
            </Link>
          </NextLink>
        ))}
      </div>
      <div className={classes.menu}>
        {links[1].map((item, index) => (
          <NextLink key={index} href={item.href}>
            <Link className={classes.link}>
              <Trans id={item.title.id} />
            </Link>
          </NextLink>
        ))}
      </div>
    </div>
  )
}

export default Menu
