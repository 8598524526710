import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

import TopBanner from './TopBanner'
import MainMenu from './MainMenu'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 1 // display box shadow top bar
  }
}))

export const MenuBar = ({ primaryLinks, secondaryLinks }) => {
  const classes = useStyles()

  return (
    <AppBar
      color='default'
      elevation={0}
      component='div'
      className={classes.root}
      position='relative'
    >
      <TopBanner links={secondaryLinks} />
      <MainMenu links={primaryLinks} />
    </AppBar>
  )
}

export default MenuBar
