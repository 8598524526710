import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'

const defaultOptions = {
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  },
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'include', // include, *same-origin, omit
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer' // no-referrer, *client
}

const internalUrls = [
  process.env.NEXT_PUBLIC_API_URL,
  process.env.NEXT_PUBLIC_APP_URL,
  process.env.NEXT_PUBLIC_AUTH_URL,
  process.env.NEXT_PUBLIC_CUSTOMER_URL,
  process.env.NEXT_PUBLIC_DASHBOARD_URL
].map(internalUrl => (new URL(internalUrl)).host)

const getContentType = res => {
  if (!res.headers.has('content-type')) {
    return null
  }

  return get(res.headers.get('content-type').split(';'), '[0]')
}

const getResponseType = res => {
  switch (getContentType(res)) {
    case 'application/json':
      return 'json'
    case 'text':
    default:
      return 'text'
  }
}

class ApiError extends Error {
  constructor (message, { status, body }) {
    super(message)

    this.name = 'ApiError'
    this.status = status
    this.body = body
  }
}
class FechError extends Error {
  constructor (message) {
    super(message)

    this.name = 'FechError'
  }
}

const parseResponse = async res => {
  const type = getResponseType(res)
  const { status, statusText } = res

  return {
    type,
    status,
    statusText,
    body: await (type => {
      switch (type) {
        case 'json':
          return res.json()
        default:
          return res.text()
      }
    })(type)
  }
}

export const $fetch = async (url, options = {}) => {
  try {
    const { host } = new URL(url)

    const payload = internalUrls.includes(host)
      ? merge({}, defaultOptions, options)
      : options

    const hasContentType = has(payload, 'headers["content-type"]')
    const contentType = get(payload, 'headers["content-type"]')

    if (has(payload, 'body')) {
      if (
        !hasContentType ||
        (hasContentType && contentType === 'application/json')
      ) {
        payload.body = JSON.stringify(payload.body)
      }
    }

    const response = await fetch(url, payload)

    if (response.status === 204) {
      return true
    }

    const { status, body, ...rest } = await parseResponse(response)

    if (!response.ok) {
      // 4xx error
      if (response.status % 400 < 99) {
        throw new ApiError(response.statusText, { status, body })
      }

      // 5xx error
      throw new Error(response.statusText)
    }

    return body
  } catch (e) {
    console.log('fetch error', e)

    throw e instanceof ApiError ? e : new FechError(e.message)
  }
}

export const $get = async (url, options = {}) =>
  $fetch(url, { method: 'GET', ...options })

export const $delete = async (url, options = {}) =>
  $fetch(url, { method: 'DELETE', ...options })

export const $post = async (url, data = {}, options = {}) =>
  $fetch(url, {
    method: 'POST',
    body: data,
    ...options
  })

export const $put = async (url, data = {}, options = {}) =>
  $fetch(url, {
    method: 'PUT',
    body: data,
    ...options
  })

export const $patch = async (url, data = {}, options = {}) =>
  $fetch(url, {
    method: 'PATCH',
    body: data,
    ...options
  })
