import { $get, appUrl as apiUrl } from '@slc/fetch'
import isEmpty from 'lodash/isEmpty'

export const countryInfos = async ({ lang }, options) => {
  if (isEmpty(lang)) {
    throw new Error('Missing lang')
  }

  return $get(apiUrl(`/${lang}/country`), options)
}
