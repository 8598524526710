import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'

export function Banner({ Icon, action, children }) {
  return (
    <Box clone pt={2} pr={2} pb={2} pl={2} mt={1} mb={2}>
      <Paper elevation={1}>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          {Icon && (
            <Grid item>
              <Box bgcolor="primary.main" clone>
                <Avatar>
                  <Icon />
                </Avatar>
              </Box>
            </Grid>
          )}
          <Grid item>{children}</Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>{action}</Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
