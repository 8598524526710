import { links, getCustomerLinkHref } from '@slc/app-links'

const { customer } = links

const l = link => ({
  ...link,
  href: getCustomerLinkHref(link)
})

export const menu = {
  dashboard: () => l(customer.dashboard),
  order: roles => ([
    l(customer.orders),
    roles.hasQuotes && l(customer.quotes)
    // l(customer.invoices),
  ]),
  credits: () => ([
    l(customer.topup),
    l(customer.statements)
  ]),
  user: () => ([
    l(customer.support),
    l(customer.infos)
  ]),
  signout: () => l(customer.signout)
}
