import isArray from 'lodash/isArray'
import first from 'lodash/first'

export const getLangInfo = (code, langs = []) =>
  first(langs.filter(lang => lang.code === code))

export const getLangInfos = (codes = [], langs = []) => {
  if (!isArray(codes)) {
    return [getLangInfo(codes, langs)]
  }

  return codes.map(code => getLangInfo(code, langs))
}
