import get from 'lodash/get'
import isString from 'lodash/isString'

import { insertParamsIntoUrl } from '@slc/utils'

import { app, auth, customer, dashboard } from './links'

// --> copy and adapted from @slc/links
const $getLink = (name, collection = null, baseUrl = null) => {
  const link = isString(name) ? get(collection, name) : name

  if (!link) {
    throw new Error(`Invalid link #[${name}] for collection ${collection.$name}.`)
  }

  if (!baseUrl) {
    return link
  }

  return {
    ...link,
    href: `${baseUrl}${link.href}`
  }
}

const $getLinkHref = (name, { params, query } = {}, collection = null, baseUrl = '') => {
  if (!baseUrl) {
    throw new Error('Missing base url')
  }

  if (!collection) {
    throw new Error('Missing collection')
  }

  const link = get(isString(name) ? $getLink(name, collection) : name, 'href')

  return insertParamsIntoUrl(`${baseUrl}${link}`, params, query)
}

export const getAuthLinkHref = (name, params, query) =>
  $getLinkHref(name, { params, query }, auth, process.env.NEXT_PUBLIC_AUTH_URL)

export const getAppLinkHref = (name, params, query) =>
  $getLinkHref(name, { params, query }, app, process.env.NEXT_PUBLIC_APP_URL)

export const getCustomerLinkHref = (name, params, query) =>
  $getLinkHref(name, { params, query }, customer, process.env.NEXT_PUBLIC_CUSTOMER_URL)

export const getDashboardLinkHref = (name, params, query) =>
  $getLinkHref(name, { params, query }, dashboard, process.env.NEXT_PUBLIC_DASHBOARD_URL)

// <--

const pathPattern = /^https?:\/\/([^/]+)(.*)$/

export const isLinkActive = (href, currentHost, currentPath = '') => {
  const match = href.match(pathPattern)

  if (!match) {
    return false
  }

  const [, host, path] = match
  const $path = path === '' ? '/' : path

  return (host === currentHost) && ($path === currentPath)
}
