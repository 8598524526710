import { defineMessage } from '@lingui/macro'

export const links = {
  // dashboard
  dashboard: {
    href: '',
    title: defineMessage({
      id: 'links.dashboard.dashboard'
    })
  }
}
