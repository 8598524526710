import { CUSTOMER_ROLE, PREMIUM_ROLE, RESELLER_ROLE } from '@slc/constants'

import { useUser } from '@auth0/nextjs-auth0'
import get from 'lodash/get'

export const getRoles = (user) => get(user, 'roles', [])

export const isCustomer = (roles = []) => roles.includes(CUSTOMER_ROLE)

export const isReseller = (roles = []) => roles.includes(RESELLER_ROLE)

export const isPremium = (roles = []) => roles.includes(PREMIUM_ROLE)

export const hasCredit = (user) => {
  const roles = getRoles(user)

  return isReseller(roles) || isPremium(roles)
}

export const hasQuote = (user) => isReseller(getRoles(user))

export const getSessionRoles = (user) =>
  get(user, 'https://studio-lowcost.com/roles', [])

export const hasCustomerRoleOnly = () => {
  const { user } = useUser()
  const roles = getSessionRoles(user)

  return roles.length === 1 && isCustomer(roles)
}

/**
 * verify current user from session has access to credit role (for next app)
 * @returns boolean
 */
export const hasCreditAccess = () => {
  const { user } = useUser()
  const roles = getSessionRoles(user)

  return isReseller(roles) || isPremium(roles)
}

/**
 * verify current user from session has access to quote role (for next app)
 * @returns boolean
 */
export const hasQuoteAccess = () => {
  const { user } = useUser()
  const roles = getSessionRoles(user)

  return isReseller(roles)
}
