import get from 'lodash/get'
import { formatPrice } from './currency'

export const buildTotalAndTaxes = ({ subtotal, taxInfos }) => {
  const taxPercent = get(taxInfos, 'percent', 0)

  const taxes = taxPercent > 0 ? Math.floor(subtotal * taxPercent) / 100 : 0
  const total = subtotal + taxes

  return {
    subtotal,
    taxes,
    total,
  }
}

export const buildTotalAndTaxesPrices = ({ subtotal, currency, taxInfos }) => {
  const { taxes, total } = buildTotalAndTaxes({ subtotal, taxInfos })

  return {
    subtotal: formatPrice({ value: subtotal, currency }),
    taxes: formatPrice({ value: taxes, currency }),
    total: formatPrice({ value: total, currency }),
  }
}
