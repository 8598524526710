import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'

import NextLink from 'next/link'
import Link from '@material-ui/core/Link'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#2c2c2c'
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    margin: `0 ${theme.spacing(1)}px`,
    textTransform: 'uppercase'
  }
}))

export const MainMenu = ({ links }) => {
  const classes = useStyles()

  return (
    <Toolbar disableGutters className={classes.root}>
      <Container maxWidth='lg' component='nav' className={classes.menu}>
        {links.map((item, index) => (
          <NextLink key={index} href={item.href} passHref>
            <Link className={classes.link}>
              <Trans id={item.title.id} />
            </Link>
          </NextLink>
        ))}
      </Container>
    </Toolbar>
  )
}

export default MainMenu
