import React from 'react'
import AppBar from '@material-ui/core/AppBar'

import TopBar from './TopBar'
import MenuBar from './MenuBar'

import { ElevationScroll } from '@slc/ui'

import { mainMenu, topMenu, secondaryMenu } from '../../config/links'

export const Header = ({ primaryLinks = mainMenu, secondaryLinks = secondaryMenu }) => {
  return (
    <ElevationScroll>
      <AppBar>
        <TopBar links={topMenu} />
        <MenuBar
          primaryLinks={primaryLinks}
          secondaryLinks={secondaryLinks}
        />
      </AppBar>
    </ElevationScroll>
  )
}

export default Header
