import { useState, useCallback, cloneElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import omit from 'lodash/omit'
import get from 'lodash/get'
import { PaginatedContent } from './PaginatedContent'
import { DefaultItemRenderer } from './DefaultItemRenderer'

const useStyles = makeStyles((theme) => ({
  list: {
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
}))

export function FetchableList({
  fetchInfos,
  toolbar = null,
  Renderer = DefaultItemRenderer,
  fieldIndex = null,
  Header = null,
  gridOptions = {
    xs: 12,
  },
}) {
  const classes = useStyles()
  const [filters, setFilters] = useState({})

  const setFilter = useCallback(
    (filter) => {
      const [name] = Object.keys(filter)

      setFilters(
        filter[name] === null
          ? omit(filters, name)
          : {
              ...filters,
              ...filter,
            },
      )
    },
    [filters],
  )

  return (
    <PaginatedContent
      fetchInfos={fetchInfos}
      toolbar={cloneElement(toolbar, {
        filters,
        setFilter,
      })}
      filters={filters}
      render={({ results }) => (
        <>
          {Header && <Header />}{' '}
          <Grid container spacing={2} className={classes.list}>
            {' '}
            {results.map((data, index) => (
              <Grid
                item
                key={fieldIndex ? get(data, fieldIndex, index) : index}
                {...gridOptions}
              >
                <Renderer data={data} />
              </Grid>
            ))}{' '}
          </Grid>
        </>
      )}
    />
  )
}
