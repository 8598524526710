import { defineMessage } from '@lingui/macro'

export const links = {
  home: {
    href: '/',
    title: defineMessage({
      id: 'links.app.home'
    })
  },
  // shop
  order: {
    href: '/order',
    title: defineMessage({
      id: 'links.app.order'
    })
  },
  orderMusic: {
    href: '/order/music',
    title: defineMessage({
      id: 'links.app.orderMusic'
    })
  },
  orderVoice: {
    href: '/order/voice',
    title: defineMessage({
      id: 'links.app.orderVoice'
    })
  },
  orderMessage: {
    href: '/order/message',
    title: defineMessage({
      id: 'links.app.orderMessage'
    })
  },
  cart: {
    href: '/order/cart',
    title: defineMessage({
      id: 'links.app.cart'
    })
  },
  checkout: {
    href: '/order/checkout',
    title: defineMessage({
      id: 'links.app.checkout'
    })
  },
  waitingPayment: {
    href: '/order/:oid/payment/waiting',
    title: defineMessage({
      id: 'links.app.waitingPayment'
    })
  },
  creditPayment: {
    href: '/order/:oid/payment/credit',
    title: defineMessage({
      id: 'links.app.creditsPayment'
    })
  },
  creditPaymentComplete: {
    href: '/order/:oid/payment/credit/success',
    // title: defineMessage({
    //   id: 'links.app.creditPaymentComplete'
    // })
  },
  creditPaymentFailure: {
    href: '/order/:oid/payment/credit/failure',
    // title: defineMessage({
    //   id: 'links.app.creditPaymentFailure'
    // })
  },
  cartPayment: {
    href: '/order/:oid/payment/card',
    title: defineMessage({
      id: 'links.app.cartPayment'
    })
  },
  cartPaymentComplete: {
    href: '/order/:oid/payment/card/success',
    // title: defineMessage({
    //   id: 'links.app.cartPaymentComplete'
    // })
  },
  cartPaymentFailure: {
    href: '/order/:oid/payment/card/failure',
    // title: defineMessage({
    //   id: 'links.app.cartPaymentFailure'
    // })
  },
  // static
  about: {
    href: '/about',
    title: defineMessage({
      id: 'links.app.about'
    })
  },
  contact: {
    href: '/contact',
    title: defineMessage({
      id: 'links.app.contact'
    })
  },
  pricing: {
    href: '/pricing',
    title: defineMessage({
      id: 'links.app.pricing'
    })
  },
  // auths
  auth: {
    href: '/auth',
    title: defineMessage({
      id: 'links.app.auth'
    })
  },
  signup: {
    href: '/auth/signup',
    title: defineMessage({
      id: 'links.app.signup'
    })
  },
  signin: {
    href: '/api/auth/login',
    title: defineMessage({
      id: 'links.app.signout'
    })
  },
  signout: {
    href: '/api/auth/logout',
    title: defineMessage({
      id: 'links.app.signout'
    })
  },
  // legals
  terms: {
    href: '/legals/terms-of-use',
    title: defineMessage({
      id: 'links.app.terms'
    })
  },
  cookies: {
    href: '/legals/cookie-policy',
    title: defineMessage({
      id: 'links.app.cookies'
    })
  },
  privacy: {
    href: '/legals/privacy-policy',
    title: defineMessage({
      id: 'links.app.privacy'
    })
  },
  notice: {
    href: '/legals/notice',
    title: defineMessage({
      id: 'links.app.notice'
    })
  },
  support: {
    href: '/support',
    title: defineMessage({
      id: 'links.app.support'
    })
  },
  // reseller
  affiliate: {
    href: '/affiliates',
    title: defineMessage({
      id: 'links.app.affiliate'
    })
  }
}
