import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

import NextLink from 'next/link'

import Grid from '@material-ui/core/Grid'
import NoSsr from '@material-ui/core/NoSsr'
// import Breadcrumbs from '@material-ui/core/Breadcrumbs'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { Link, Logo } from '@slc/ui'
import { Trans } from '@lingui/macro'

import { CustomerProvider } from '@slc/context'
import { useRouter } from 'next/router'

import { getAppLinkHref, links, isLinkActive } from '@slc/app-links'
import { hasCreditAccess, hasQuoteAccess } from '@slc/user'

import compact from 'lodash/compact'
import { FULLPAGE_LAYOUT } from '@/config/layout'
import { menu } from '@/config/links'
import { Avatar } from './Avatar'

const drawerWidth = 320

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    padding: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
    padding: theme.spacing(2),
  },
  logo: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  avatar: {
    marginBlock: theme.spacing(3),
    textAlign: 'center',
  },
  signoutLink: {
    marginTop: theme.spacing(3),
  },
  content: {
    flexGrow: 1,
    paddingInline: theme.spacing(3),
    maxWidth: '1200px',
    minHeight: '100vh',
  },
}))

const useLinkStyles = makeStyles((theme) => ({
  menuIcon: {
    minWidth: theme.spacing(4),
  },
  listItem: {
    borderLeftWidth: '3px',
    borderLeftStyle: 'solid',
    borderLeftColor: ({ isActive }) =>
      isActive ? theme.palette.primary : theme.palette.common.white,
  },
  listItemText: {
    display: 'flex',
    color: ({ isActive }) =>
      isActive ? theme.palette.primary.main : theme.palette.grey[500],
  },
}))

export function MenuItemLink({
  title,
  href,
  currentPath,
  currentHost,
  Icon,
  clientSide = true,
}) {
  const classes = useLinkStyles({
    isActive: isLinkActive(href, currentHost, currentPath),
  })

  return (
    <ListItem dense className={classes.listItem}>
      <Link
        href={href}
        className={classes.listItemText}
        clientSide={clientSide}
      >
        {Icon && (
          <ListItemIcon className={classes.menuIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={<Trans id={title.id} />} />
      </Link>
    </ListItem>
  )
}

export const buildMenu = ({ section, menuLinks, currentPath, currentHost }) => (
  <>
    <ListSubheader>{section}</ListSubheader>
    {compact(menuLinks).map(({ title, href }, index) => (
      <MenuItemLink
        key={index}
        title={title}
        href={href}
        currentPath={currentPath}
        currentHost={currentHost}
      />
    ))}
  </>
)

export function PageLayout({ children }) {
  const classes = useStyles()
  const router = useRouter()
  const currentPath = router.pathname
  const currentHost = window.location.host

  const roles = {
    hasCredit: hasCreditAccess(),
    hasQuotes: hasQuoteAccess(),
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.logo}>
          <NextLink href={getAppLinkHref(links.app.home)} passHref>
            <a>
              <Logo ratio={1.2} />
            </a>
          </NextLink>
        </div>

        <div className={classes.avatar}>
          <Avatar />
        </div>

        <List component="nav">
          <NoSsr>
            <MenuItemLink
              {...menu.dashboard(roles)}
              currentPath={currentPath}
            />
            <MenuItemLink
              title={links.app.order.title}
              href={getAppLinkHref(links.app.order)}
              currentPath={currentPath}
              currentHost={currentHost}
            />

            {buildMenu({
              section: <Trans id="customer.drawer.section.order" />,
              menuLinks: menu.order(roles),
              currentPath,
              currentHost,
              classes,
            })}

            {roles.hasCredit &&
              buildMenu({
                section: <Trans id="customer.drawer.section.credits" />,
                menuLinks: menu.credits(roles),
                currentPath,
                currentHost,
                classes,
              })}
            {buildMenu({
              section: <Trans id="customer.drawer.section.user" />,
              menuLinks: menu.user(roles),
              currentPath,
              currentHost,
              classes,
            })}
          </NoSsr>
        </List>

        <div className={classes.signoutLink}>
          <MenuItemLink
            {...menu.signout(roles)}
            Icon={ExitToAppIcon}
            classes={classes}
            currentPath={currentPath}
            currentHost={currentHost}
            clientSide={false}
          />
        </div>
      </Drawer>

      <main className={classes.content}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <Breadcrumbs />
          </Grid> */}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export function FullPageLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export function Layout({ children }) {
  const UsedLayout =
    children.type.layout === FULLPAGE_LAYOUT ? FullPageLayout : PageLayout

  return (
    <CustomerProvider>
      <UsedLayout>{children}</UsedLayout>
    </CustomerProvider>
  )
}
