import { defineMessage } from '@lingui/macro'

export const links = {
  // signin: {
  //   href: '/signin',
  //   title: defineMessage({
  //     id: 'links.signin',
  //     message: 'Connexion'
  //   })
  // },
  // signup
  signup: {
    href: '/signup',
    title: defineMessage({
      id: 'links.auth.signup'
    })
  },
  signupRegister: {
    href: '/signup/register',
    // title: defineMessage({
    //   id: 'links.auth.signupRegister'
    // })
  },
  signupInformations: {
    href: '/signup/informations',
    // title: defineMessage({
    //   id: 'links.auth.signupInformations'
    // })
  },
  signupDetails: {
    href: '/signup/details',
    // title: defineMessage({
    //   id: 'links.auth.signupDetails'
    // })
  },
  signout: {
    href: '/api/auth/signout',
    title: defineMessage({
      id: 'links.auth.signout'
    })
  }
}
