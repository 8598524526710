import React from 'react'

import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import { Trans } from '@lingui/macro'

export function AlertDialog({
  title,
  children,
  buttonLabel,
  onClose,
  open,
  ...other
}) {
  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          {buttonLabel || <Trans id="ui.dialog.alert.button.label" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
