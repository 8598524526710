import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { PageLoader } from '@slc/ui'

export const Persistence = ({ store, children }) => {
  if (!store) {
    return children
  }

  const persistor = persistStore(store, {}, function () {
    console.log('store rehydrated', ...arguments)
  })

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<PageLoader />}>
        {children}
      </PersistGate>
    </Provider>
  )
}
