import React from 'react'
import IconButton from '@material-ui/core/IconButton'

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import NextLink from 'next/link'

import { useUser } from '@auth0/nextjs-auth0'

import UserMenu from './UserMenu'

import { links, getAppLinkHref } from '@slc/app-links'

export const AccountButton = ({ buttonClassName, iconClassName }) => {
  const { user } = useUser()

  return user
    ? <UserMenu buttonClassName={buttonClassName} iconClassName={iconClassName} />
    : (
      <NextLink href={getAppLinkHref(links.app.auth)} passHref>
        <IconButton aria-label='customer' className={buttonClassName}>
          <AccountCircleOutlinedIcon className={iconClassName} />
        </IconButton>
      </NextLink>
      )
}

export default AccountButton
