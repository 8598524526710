import { makeStyles } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'

import { LangFlag } from '@slc/ui'

import { getMessageSelection, getLangFlag, findLang } from '@slc/data-helpers'
import { useLangs } from '@slc/context'

import {
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE,
} from '@slc/constants'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
    fontFamily: 'courier',
  },
  notice: {
    marginBottom: theme.spacing(1),
  },
  customFrom: {
    display: 'flex',
    alignItems: 'center',
  },
  customFromLang: {
    padding: theme.spacing(1),
  },
}))

export function MessageSelection({ lid, category, selection }) {
  const classes = useStyles()
  const langs = useLangs()

  const messageSelection = getMessageSelection({ lid, category, selection })

  switch (category) {
    case STANDARD_MESSAGE:
    case SEMI_STANDARD_MESSAGE: {
      const { blocks } = messageSelection

      return (
        <Typography component="p" variant="body2">
          {blocks[0]}
        </Typography>
      )
    }

    case CUSTOM_MESSAGE: {
      const { blocks, translated, origin } = messageSelection

      if (!translated) {
        return blocks.map((text, index) => (
          <div key={index}>
            <Typography
              component="p"
              variant="caption"
              className={classes.text}
            >
              {text}
            </Typography>
          </div>
        ))
      }

      if (!origin) {
        return (
          <Typography
            component="p"
            variant="caption"
            className={classes.notice}
          >
            <Trans id="customer.component.messageSelection.custom.block.translated" />
          </Typography>
        )
      }

      return (
        <div>
          <Typography
            component="p"
            variant="caption"
            className={classes.notice}
          >
            <Trans
              id="customer.component.messageSelection.custom.block.translatedFrom"
              values={{ lang: getLangFlag(findLang(origin, langs)) }}
            />
          </Typography>
          <div className={classes.customFrom}>
            <span className={classes.customFromLang}>
              <LangFlag lang={langs.find((lang) => lang.lid === origin)} />
            </span>
            <Typography component="p" variant="body2">
              {blocks[0]}
            </Typography>
          </div>
        </div>
      )
    }
  }
}
