import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { useSelector } from 'react-redux'

import isEmpty from 'lodash/isEmpty'
import property from 'lodash/property'

import NextLink from 'next/link'

import { links, getAppLinkHref } from '@slc/app-links'

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

export const CartButton = ({ buttonClassName, iconClassName }) => {
  const messages = useSelector(property('cart.messages'))
  const hasMessages = !isEmpty(messages)
  const badgeProps = {}

  if (hasMessages) {
    badgeProps.badgeContent = messages.length
  }

  const Icon = hasMessages ? ShoppingCartOutlinedIcon : ShoppingCartRoundedIcon

  return (
    <NextLink href={getAppLinkHref(links.app.cart)} passHref>
      <IconButton
        aria-label='cart'
        className={buttonClassName}
        classes={{
          disabled: buttonClassName
        }}
      >
        <StyledBadge {...badgeProps} color='secondary' invisible={!hasMessages}>
          <Icon className={iconClassName} />
        </StyledBadge>
      </IconButton>
    </NextLink>
  )
}

export default CartButton
