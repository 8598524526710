import { useState, forwardRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded'

import NextLink from 'next/link'
import Link from '@material-ui/core/Link'

import { getAppLinkHref, getCustomerLinkHref, links } from '@slc/app-links'

import { Trans } from '@lingui/macro'

const $links = {
  dashboard: getCustomerLinkHref(links.customer.dashboard),
  signout: getAppLinkHref(links.app.signout)
}

export const AccountButton = ({ buttonClassName, iconClassName }, ref) => {
  const [anchorEl, setAnchorEl] = useState()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={buttonClassName}
      >
        <AccountCircleRoundedIcon className={iconClassName} />
      </IconButton>
      <Menu
        elevation={1}
        id='user-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={handleClose}>
          <NextLink href={$links.dashboard} passHref>
            <Link>
              <Trans id={links.customer.dashboard.title.id} />
            </Link>
          </NextLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            component='a'
            href={$links.signout}
          >
            <Trans id={links.app.signout.title.id} />
          </Link>
        </MenuItem>
      </Menu>
    </>
  )
}

export default forwardRef(AccountButton)
