import toPairs from 'lodash/toPairs'

export const choicesFromDict = object => {
  const pairs = toPairs(object)

  return pairs.map(([value, label]) => ({
    label,
    value
  }))
}
