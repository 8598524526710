import { React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PageLoader } from '@slc/ui'

import { useAppApi } from '@slc/fetch'

import { Trans } from '@lingui/macro'
import { FetchError } from './FetchError'

const useStyles = makeStyles((theme) => ({
  empty: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
}))

function EmptyPlaceholder({ className }) {
  return (
    <div className={className}>
      <Trans id="ui.fetch.content.empty.placeholder" />
    </div>
  )
}

function Content({ data, Renderer, classes }) {
  if (!data) {
    return <EmptyPlaceholder className={classes.empty} />
  }

  return <Renderer data={data} />
}

export const FetchableContent = ({
  useApi,
  fetchInfos,
  Renderer,
  Loader = null,
}) => {
  const classes = useStyles()

  const { data, error, statusCode, hasFetched, failed } = (useApi || useAppApi)(
    fetchInfos,
  )

  console.log({ data, error, statusCode, hasFetched, failed })
  let content = null
  let loader = null

  if (hasFetched) {
    content = failed ? (
      <FetchError statusCode={statusCode} error={error} />
    ) : (
      <Content data={data} Renderer={Renderer} classes={classes} />
    )
  } else {
    loader = Loader ? <Loader /> : <PageLoader />
  }

  return !hasFetched ? loader : content
}
