import { $get, appUrl as apiUrl } from '@slc/fetch'

export const getCustomerInfos = (options) => {
  return $get(apiUrl('/customer/infos'), options)
}

export const getCustomerBalance = (options) => {
  return $get(apiUrl('/customer/balance'), options)
}

export const getCustomerPricing = (options) => {
  return $get(apiUrl('/customer/pricing'), options)
}
