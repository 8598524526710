import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import { setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { i18nActivate } from '@slc/i18n'

import { AppLayout } from './layout'

export const i18n = setupI18n()

export function App({ Layout, theme, Component, pageProps, err, store }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  const { locale, messages } = pageProps

  useEffect(() => {
    i18nActivate(i18n, locale, messages)
  }, [locale])

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <I18nProvider i18n={i18n}>
          <AppLayout
            store={store}
            Layout={Layout}
            Component={Component}
            pageProps={pageProps}
            err={err}
          />

        </I18nProvider>
      </ThemeProvider>
    </>
  )
}
