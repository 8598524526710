import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'

const initialState = {
  name: '',
  acceptation: false,
  paymentTypes: [],
  paymentType: '',
}

export const isOrderCheckoutReady = (infos = {}) => {
  const acceptation = get(infos, 'acceptation')
  const paymentType = get(infos, 'paymentType', [])

  return isBoolean(acceptation) && !!acceptation && !isEmpty(paymentType)
}

export const orderContextBuilder = () => {
  const OrderContext = createContext(initialState)

  const useOrder = () => useContext(OrderContext)

  function OrderProvider({
    name = '',
    acceptation = false,
    paymentType = '',
    children,
  }) {
    const [$name, setName] = useState(name)
    const [$acceptation, setAcceptation] = useState(acceptation)
    const [$paymentType, setPaymentType] = useState(paymentType)

    const addAcceptation = useCallback(
      (value) => {
        setAcceptation(value)
      },
      [setAcceptation, $paymentType],
    )

    const addPaymentType = useCallback(
      (value) => {
        setPaymentType(value)
      },
      [setPaymentType, $acceptation],
    )

    const ctxValue = useMemo(
      () => ({
        ...initialState,
        isValid: isOrderCheckoutReady({
          acceptation: $acceptation,
          paymentType: $paymentType,
        }),
        acceptation: $acceptation,
        name: $name,
        paymentType: $paymentType,
        setName,
        addAcceptation,
        addPaymentType,
      }),
      [
        $acceptation,
        $name,
        $paymentType,
        // mutators
        setName,
        addAcceptation,
        addPaymentType,
      ],
    )

    return (
      <OrderContext.Provider value={ctxValue}>{children}</OrderContext.Provider>
    )
  }

  return {
    useOrder,
    OrderProvider,
  }
}
